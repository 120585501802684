import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
//import './assets/css/login.css';
import './../assets/css/login.css';


export class CommonDialog extends Component<any, any> {


    constructor(props: any) {
        super(props);
        debugger;
        
    } 
            
    render = () => {
        debugger;
        return (
            <div>
                <Modal
                    {...this.props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    style={{ opacity: 1 }}>
                    <Modal.Header>

                        <Modal.Title id="contained-modal-title-vcenter" >
                            {this.props.modalTitle}

                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            {this.props.modalMessage}<br />

                        </p>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.props.onCommonDialogHide}>OK</Button>
                    </Modal.Footer>

                </Modal>
            </div>
        );
    }
}