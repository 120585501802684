import React, { Component } from 'react';
import { RouteComponentProps, Redirect } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../store/WeatherForecasts';
import { RegisterUserModal } from './RegisterUserModal';
import { ForgotPassword } from './ForgotPassword';
import { CommonDialog } from './common/CommonDialog';
import ReCAPTCHA from 'react-google-recaptcha';
import appSettings from './../appsettings.json';
import { Button } from 'react-bootstrap';
import './assets/css/login.css';
//import logosvg from './assets/images/cleverpinamelogo.png';
import loginjpg from './assets/images/login.jpg';


class Login extends React.Component<any, any> {

    _reCaptchaRef: any;

    constructor(props: any) {
        super(props);
        this._reCaptchaRef = React.createRef();
        this.state = {
            emailId: "",
            password: "",
            emptyPassword: true,
            emptyField: false,
            showRegisterModal: false,
            showForgotPassword: false,
            showEmailSentMessage: false,
            invalidUserIdOrPassword: false,
            loginFailedMessage: "",
            modalMessage: "",
            modalTitle: "",
            redirectToApp: false
        };
    }

    showEmailSentConfirmation = (emailId: string) => {
        this.setState({ modalTitle: "Email Sent", modalMessage: "A confirmation email has been sent to " + emailId, showEmailSentMessage: true, showRegisterModal: false });
    }

    hideEmailSentModal = () => {
        this.setState({ showEmailSentMessage: false });
    }

    hideEmptyFieldModal = () => {
        this.setState({ emptyField: false });
    }

    hideRegisterModel = () => {
        this.setState({ showRegisterModal: false });
    }

    hideForgotPasswordModel = () => {
        this.setState({ showForgotPassword: false });
    }

    asyncScriptOnLoad = () => {
        this.setState({ callback: "called!" });
        console.log("scriptLoad - reCaptcha Ref-", this._reCaptchaRef);
    };

    handleChange = (value: any) => {
        console.log("Captcha value:", value);
        this.setState({ token: value });//, disableResetButton: false
    };

    validateEmailId = (emailAddress: string) => {
        var emailValid = false;
        if (!!emailAddress.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) emailValid = true;
        this.setState({ validEmail: emailValid });
    };

    setFormFields = (event: any) => {
        debugger;
        const { target: { name, value } } = event;
        this.setState({ [name]: value });
        if (name == 'emailId') {
            this.validateEmailId(value);
        }
        else if (name == 'password') {
            this.setState({ emptyPassword: false });
        }      
    };

    validateUserAndSubmit = () => {
        if (this.state.password.trim() === "" || this.state.emailId.trim() === "" || !this.state.validEmail) {
            this.setState({ emptyField: true });
            return;
        }
        else {
            
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    emailId: this.state.emailId,
                    password: this.state.password,
                    token: this.state.token
                })
            };

            fetch('api/CleverPiData/ValidateAndLoginUser', requestOptions)
                .then(response => response.json())
                .then(response => {
                    debugger;
                    //this.setState({ disableAllFields: false });
                    console.log(response)
                    if (response.ErrorMessage && response.ErrorMessage !== "") {
                        debugger;
                        console.log("Response message : " + response.ErrorMessage);
                        this.setState({ invalidUserIdOrPassword: true, loginFailedMessage: response.ErrorMessage})

                    }
                    else {
                        debugger;
                        sessionStorage.setItem("id_token", response.LoginToken);
                        this.setState({ redirectToApp: true });
                        //this.props.showEmailSentModal(response.EmailId);
                    }

                });


        }
    }
   
    componentDidMount = () => {
        this._reCaptchaRef.current.execute();
    }

    render = () => {
        if (this.state.redirectToApp) {
            return (<Redirect to='/dashboard' />);
        }

        return (
            <div className={"row login-img"}>
                <div className={"col-sm-3 login-section-wrapper"} >
                    {/*<div className={"brand-wrapper"}>*/}
                    {/*    <img src={logosvg} alt="logo" className="logo" />*/}
                    {/*</div>*/}
                    <div className={"login-wrapper my-auto"}>
                        <h1 className={"login-title"}>Log in</h1>

                        <div className={"form-group"}>
                            <label>Email</label>
                            <input type="email"
                                name="emailId"
                                className={!this.state.emailId ? "form-control" : this.state.validEmail ? "form-control validTextStyle" : "form-control invalidTextStyle"}
                                placeholder="email@example.com"
                                value={this.state.emailId}
                                onChange={this.setFormFields}
                            />
                        </div>
                        <div className={"form-group mb-4"}>
                            <label>Password</label>
                            <input type="password"
                                name="password"
                                id="password"
                                className={!this.state.password ? "form-control" : this.state.emptyPassword ? "form-control invalidTextStyle" : "form-control validTextStyle"}
                                value={this.state.password}
                                onChange={this.setFormFields}
                                placeholder="enter your passsword"
                            />
                        </div>
                        <input name="login" id="login" className="btn btn-block login-btn" type="button" value="Login" onClick={() => { this.validateUserAndSubmit(); }} />
                        {this.state.invalidUserIdOrPassword &&
                            <div className={"errorMessageTextStyle"}>
                            {this.state.loginFailedMessage}<br />
                            </div>
                        }
                        <a className="forgot-password-link btn-link" onClick={() => { this.setState({ showForgotPassword: true }) }}>Forgot password?</a>
                        {/*<Button onClick={() => { this.setState({ showForgotPassword: true }) }} style={{ align: 'left' }} className="forgot-password-link">Forgot password?</Button>*/}
                        <p className="login-wrapper-footer-text">Don't have an account? <a className="text-reset btn-link" onClick={() => { this.setState({ showRegisterModal: true }) }}>Register here</a></p>
                        {/*<Button onClick={() => { this.setState({ showRegisterModal: true }) }} style={{ align: 'right' }} className="text-reset">Sign up</Button>*/}
                        <ReCAPTCHA
                            style={{ display: 'inline-block' }}
                            size="invisible"
                            ref={this._reCaptchaRef}
                            sitekey={appSettings.SiteKey}
                            onChange={this.handleChange}
                            asyncScriptOnLoad={this.asyncScriptOnLoad}
                        />
                        
                    </div>
                </div>
                <div className="col-sm-9 message-section-wrapper"  >
                    <p>Enable your business for next generation technology,<br />
                        Solutions which are empowered by AI and IoT </p>
                    {/*<div className="login-img">
                        <img src={loginjpg} alt="login image" className="login-img " />
                    </div>*/}
                </div>

                {this.state.showRegisterModal &&
                    <RegisterUserModal
                        show={this.state.showRegisterModal}
                        onHide={this.hideRegisterModel}
                        showEmailSentModal={this.showEmailSentConfirmation}
                    />}

                {this.state.showForgotPassword &&
                    <ForgotPassword
                        show={this.state.showForgotPassword}
                        onHide={this.hideForgotPasswordModel}

                    />}
                {this.state.showEmailSentMessage &&

                    <CommonDialog
                        modalTitle={this.state.modalTitle}
                        modalMessage={this.state.modalMessage}
                        onCommonDialogHide={this.hideEmailSentModal}
                        show={this.state.showEmailSentMessage}
                    //onHide={this.hideEmailSentModal}
                    />

                }
                {this.state.emptyField &&
                    <CommonDialog
                        modalTitle="Warning!"
                        modalMessage="Please enter valid Email and Password."
                        onCommonDialogHide={this.hideEmptyFieldModal}
                        show={this.state.emptyField}
                    //onHide={this.hideEmailSentModal}
                    />
                }
            </div>
        );
    }
}

export default connect(
    (state: any) => state.weatherForecasts,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Login);


