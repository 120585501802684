import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Redirect } from 'react-router';
import { Container } from 'reactstrap';
import loadingdots from './assets/images/loading.gif'; // './assets/images/login.jpg';

//import { ApplicationState } from '../store';
//import * as WeatherForecastsStore from '../store/WeatherForecasts';

// At runtime, Redux will merge together...
//type EmailVerificationProps =
//    WeatherForecastsStore.WeatherForecastsState // ... state we've requested from the Redux store
//    & typeof WeatherForecastsStore.actionCreators // ... plus action creators we've requested
//    & RouteComponentProps<{ startDateIndex: string }>; // ... plus incoming routing parameters

interface IVerifyEmail {
    show: boolean;
    onHide(): void;
    showEmailSentModal(emailId: string): void;
    verificationLink: RouteComponentProps<{ verificationLink: string }>;
}


class VerifyEmail extends Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            showSuccessMessage: false,
            redirectPage: false,
            invalidLink: false
        };
    }
    // This method is called when the component is first added to the document
    componentDidMount = () => {
        this.verifyUserAndActivate();
    }

    // This method is called when the route parameters change
    //componentDidUpdate = () => {
    //    this.ensureDataFetched();
    //}

    render = () => {
        //if (this.state.showSuccessMessage) {

        //    return (<Container>
        //        <h1 id="tabelLabel2">Account has been activated by email verification</h1>
        //        <p>Please navigate to <a href='/login'> login</a> page to login to the portal.</p>
        //    </Container>);
        //    {/*return <Redirect to='/login' />;*/ }
        //}

        if (this.state.redirectPage) {
            return (<Redirect to='/login' />);
        }

        return (
            <React.Fragment>
                <Container>


                    {this.state.invalidLink ?
                        <span>
                            <h1 id="tabelLabel">Account activation by email verification FAILED!</h1>
                            <p>The navigation link is invalid. Please try with a valid link.</p>

                        </span>
                        :
                        <span>
                            <h1 id="tabelLabel1">Activating account by email verification</h1>                            
                            <p>Please wait a few moments while we verify your email and activate the account.</p>
                            <p>The page will automatically navigate to the login page once the account is activated.</p>
                            <p>
                                <img src={loadingdots} alt="Please wait..." />
                            </p>
                           
                        </span>
                    }

                    {/*{this.props.match.params.verificationLink}*/}
                </Container>
            </React.Fragment>
        );
    }

    verifyUserAndActivate = () => {
        debugger;
        console.log(this.props.match.params.verificationLink);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(this.props.match.params.verificationLink)
        };

        fetch('api/CleverPiData/ActivateUser', requestOptions)
            .then(response => response.json())
            .then(response => {
                if (response.ErrorMessage && response.ErrorMessage !== "") {
                    this.setState({ invalidLink: true });
                }
                else {
                    //this.setState({ showSuccessMessage: true });

                    //this.sleep(3000).then(x => {
                    this.setState({ redirectPage: true })
                    //});
                }

            });


    }

    //sleep = (milliseconds: any) => {
    //    return new Promise(resolve => setTimeout(resolve, milliseconds))
    //}

}

export default connect()(VerifyEmail);


