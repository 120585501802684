import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import appSettings from './../appsettings.json';
import './assets/css/login.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import './assets/css/login.css';
import { GetCurrentToken } from './common/TokenHelper';

interface IUserProfileUpdateProps {
    UserGuid: string,
    reAuthenticate(): void
}

export class CompleteProfile extends Component<IUserProfileUpdateProps, any> {
    _reCaptchaRef: any;
    constructor(props: any) {
        super(props);
        this._reCaptchaRef = React.createRef();
        this.state = {
            name: '',
            accountType: 'select',
            addressLine: '',
            cityName: '',
            stateName: '',
            countryName: '',
            phoneNo1: '',
            phoneNo2: '',
            token: ''
        };
    }

    asyncScriptOnLoad = () => {
        debugger;
        //this.setState({ callback: "called!" });
        console.log("scriptLoad - reCaptcha Ref-", this._reCaptchaRef);
    }

    handleChange = (value: any) => {
        debugger;
        console.log("Captcha value:", value);
        this.setState({ token: value });

        //if (this.state.validEmail && value !== null && this.passwordMismatchCheck() === 1) {
        //    this.setState({ disableRegisterButton: false });
        //}
        //else {
        //    this.setState({ disableRegisterButton: true });
        //}
    }

    accountTypeChange = (event: any) => {
        this.setState({ accountType: event.target.value });
    }

    setFormFields = (event: any) => {
        const { target: { name, value } } = event;
        this.setState({ [name]: value });
    }

    submitFormFields = () => {
        debugger;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': GetCurrentToken()},
            body: JSON.stringify({
                userGuid: this.props.UserGuid,
                userType: this.state.accountType == "business" ? 4: 5,
                name: this.state.name,
                address: this.state.addressLine,
                city: this.state.cityName,
                state: this.state.stateName,
                country: this.state.countryName,
                phoneNo1: this.state.phoneNo1,
                phoneNo2: this.state.phoneNo2,
                reCaptchaToken: this.state.token
            })
        };
        this.setState({ disableAllFields: true });
        fetch('api/CleverPiData/UpdateUserProfile', requestOptions)
            .then((response: any) => {
                debugger;
                this.setState({ disableAllFields: false });
                console.log(response)
                if (response.ErrorMessage && response.ErrorMessage !== "") {
                    debugger;
                    console.log("Response message : " + response.ErrorMessage);
                    this.props.reAuthenticate();
                }
                else {
                    debugger;
                    console.log("Response message : " + response.Message);
                    this.props.reAuthenticate();
                    //this.props.showEmailSentModal(response.EmailId);
                }

            });
    }

    render = () => {
        return (
            <div>
                <div className={"justify-content-center"}>
                    <div className={'col-lg-3'}>
                        <p>Please complete your profile</p>
                        <label>Choose your account type:</label>
                        &nbsp;&nbsp;
                        <select id="accountType"
                            value={this.state.accountType}
                            onChange={this.accountTypeChange}
                            className={this.state.accountType !== 'select' ? "form-control validTextStyle" : "form-control invalidTextStyle"}                    >
                            <option value="select">SELECT</option>
                            <option value="business">Business</option>
                            <option value="personal">Personal</option>
                        </select>
                    </div><br />
                    <div>
                        {(this.state.accountType == "business") &&
                            <div className={'col-lg-7'}>
                                <p>Please fill below details for your business account</p>
                                <div className={"row"}>
                                    <div className={"col"}>Business Name:
                                        <input type="text"
                                            name="name"
                                            className={this.state.name !== '' ? "form-control validTextStyle" : "form-control invalidTextStyle"}
                                            value={this.state.name}
                                            onChange={this.setFormFields}
                                        />
                                    </div>
                                    <div className={"col"}>Address:
                                    <input type="text"
                                            name="addressLine"
                                            className="form-control"
                                            value={this.state.addressLine}
                                            onChange={this.setFormFields}
                                        />
                                    </div>
                                </div>
                                <div className={"row"}>
                                    <div className={"col"}> &nbsp;
                                    </div>
                                    <div className={"col"}>City:
                                    <input type="text"
                                            name="cityName"
                                            className="form-control"
                                            value={this.state.cityName}
                                            onChange={this.setFormFields}
                                        />
                                    </div>
                                </div>
                                <div className={"row"}>
                                    <div className={"col"}> &nbsp;
                                    </div>
                                    <div className={"col"}>State:
                                    <input type="text"
                                            name="stateName"
                                            className="form-control"
                                            value={this.state.stateName}
                                            onChange={this.setFormFields}
                                        />
                                    </div>
                                </div>
                                <div className={"row"}>
                                    <div className={"col"}> &nbsp;
                                    </div>
                                    <div className={"col"}>Country:
                                    <input type="text"
                                            name="countryName"
                                            className="form-control"
                                            value={this.state.countryName}
                                            onChange={this.setFormFields}
                                        />
                                    </div>
                                </div>
                                <br />
                                <div className={"row"}>
                                    <div className={"col"}>Phone #1:
                                        <input type="text"
                                            className="form-control"
                                            value={this.state.phoneNo1}
                                            onChange={this.setFormFields}
                                        />
                                    </div>
                                    <div className={"col"}>Phone #2:
                                        <input type="text"
                                            className="form-control"
                                            value={this.state.phoneNo2}
                                            onChange={this.setFormFields}
                                        />
                                    </div>
                                
                                </div>
                                
                            </div>

                        }
                        {(this.state.accountType == "personal") &&
                            <div className={'col-lg-7'}>
                                <p>Please fill below details</p>
                                <div className={"row"}>
                                    <div className={"col"}>Full Name:
                                        <input type="text"
                                            name="name"
                                            className={this.state.name !== '' ? "form-control validTextStyle" : "form-control invalidTextStyle"}
                                            value={this.state.name}
                                            onChange={this.setFormFields}
                                        />
                                    </div>
                                    <div className={"col"}>Address:
                                        <input type="text"
                                            className="form-control"
                                            value={this.state.addressLine}
                                            onChange={this.setFormFields}
                                        />
                                    </div>
                                </div>
                                <div className={"row"}>
                                    <div className={"col"}> &nbsp;
                                    </div>
                                    <div className={"col"}>City:
                                        <input type="text"
                                            className="form-control"
                                            value={this.state.cityName}
                                            onChange={this.setFormFields}
                                        />
                                    </div>
                                </div>
                                <div className={"row"}>
                                    <div className={"col"}> &nbsp;
                                    </div>
                                    <div className={"col"}>State:
                                        <input type="text"
                                            className="form-control"
                                            value={this.state.stateName}
                                            onChange={this.setFormFields}
                                        />
                                    </div>
                                </div>
                                <div className={"row"}>
                                    <div className={"col"}> &nbsp;
                                    </div>
                                    <div className={"col"}>Country:
                                        <input type="text"
                                            className="form-control"
                                            value={this.state.countryName}
                                            onChange={this.setFormFields}
                                        />
                                    </div>
                                </div>
                                <br />
                                <div className={"row"}>
                                    <div className={"col"}>Phone #1:
                                        <input type="text"
                                            className="form-control"
                                            value={this.state.phoneNo1}
                                            onChange={this.setFormFields}
                                        />
                                    </div>
                                    <div className={"col"}>Phone #2:
                                        <input type="text"
                                            className="form-control"
                                            value={this.state.phoneNo2}
                                            onChange={this.setFormFields}
                                        />
                                    </div>
                                </div>

                            </div>
                        }
                    </div>
                    {(this.state.accountType == "personal" || this.state.accountType == "business") &&
                        <div className={'col-lg-7'}>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <br />
                                    <ReCAPTCHA
                                        style={{ display: "inline-block" }}
                                        ref={this._reCaptchaRef}
                                        sitekey={appSettings.SiteKey}
                                        onChange={this.handleChange}
                                        asyncScriptOnLoad={this.asyncScriptOnLoad}
                                    /*disabled={this.state.disableAllFields}*/
                                    />
                                </div>
                                <div className={"col"}>
                                    <span style={{ float: 'right' }}>
                                        <br />
                                        <Button onClick={this.submitFormFields} disabled={this.state.disableRegisterButton || this.state.disableAllFields} >Submit</Button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}