import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './assets/css/login.css';
import ReCAPTCHA from 'react-google-recaptcha';
import appSettings from '../appsettings.json';

export class ForgotPassword extends Component<any, any> {

    _reCaptchaRef: any;

    constructor(props: any) {
        super(props);
        debugger;
        this._reCaptchaRef = React.createRef();
        this.state = {
            disableResetButton: true,
            token: null, 
            emailId: '',
            validEmail: false
        }       
    }  

    submitEmailId = async () => {
        debugger;        
        //var token = await this.reRef.current.executeAsync();
        console.log(this.state.token);
    }

    setFormFields = (event: any) => {
        const { target: { name, value } } = event;
        this.setState({ [name]: value });
        if (name == 'emailId') this.validateEmailId(value);        
    }

    validateEmailId = (emailAddress: string) => {
        var emailValid = false;
        if (!!emailAddress.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) emailValid = true;
        this.setState({ validEmail: emailValid });

        if (emailValid && this.state.token !== null) {
            this.setState({ disableResetButton: false });
        }
        else {
            this.setState({ disableResetButton: true });
        }
    }

    asyncScriptOnLoad = () => {
        debugger;
        this.setState({ callback: "called!" });
        console.log("scriptLoad - reCaptcha Ref-", this._reCaptchaRef);
    };

    handleChange = (value: string )=> {
        debugger;
        console.log("Captcha value:", value);
        this.setState({ token: value});
        if (this.state.validEmail && value !== null) {
            this.setState({ disableResetButton: false });
        }
        else {
            this.setState({ disableResetButton: true });
        }
    };

    callback = (key: any) => {
        debugger;
        console.log(key);
    }

    render = () => {
        
        return (
            <div>
                <Modal
                    {...this.props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    style={{ opacity: 1 }}>
                    <Modal.Header>

                        <Modal.Title id="contained-modal-title-vcenter" >
                            Forgot Password
                            
                </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            Please enter your email Id to reset your password<br />

                        </p>
                        <input type="email"
                            name="emailId"
                            className={this.state.validEmail ? "form-control validTextStyle" : "form-control invalidTextStyle"}
                            placeholder="email@example.com"
                            value={this.state.emailId}
                            onChange={this.setFormFields} />

                        <br />
                            <ReCAPTCHA
                                style={{ display: "inline-block" }}
                                ref={this._reCaptchaRef}
                                sitekey={appSettings.SiteKey}
                                onChange={this.handleChange}
                                //asyncScriptOnLoad={this.asyncScriptOnLoad}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.submitEmailId} disabled={this.state.disableResetButton} >Reset Password</Button>
                        <Button onClick={this.props.onHide}>Cancel</Button>
                    </Modal.Footer>

                </Modal>             
              
            </div>
        );
    }
}