import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import appSettings from './../appsettings.json';
import './assets/css/login.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
//import emailjs from 'emailjs-com';

interface IRegisterUser {
    show: boolean;
    onHide(): void;
    showEmailSentModal(emailId: string): void;
}

export class RegisterUserModal extends Component<IRegisterUser, any> {
    _reCaptchaRef: any;
    constructor(props: any) {
        super(props);
        this._reCaptchaRef = React.createRef();
        this.state = {
            emailId: '',
            password: '',
            repassword: '',
            disableRegisterButton: true,
            token: null,
            validEmail: false,
            disableAllFields: false
        };
    }

    asyncScriptOnLoad = () => {
        debugger;
        //this.setState({ callback: "called!" });
        console.log("scriptLoad - reCaptcha Ref-", this._reCaptchaRef);
    };

    handleChange = (value: any) => {
        debugger;
        console.log("Captcha value:", value);
        this.setState({ token: value });

        if (this.state.validEmail && value !== null && this.passwordMismatchCheck() === 1) {
            this.setState({ disableRegisterButton: false });
        }
        else {
            this.setState({ disableRegisterButton: true });
        }
    };

    setFormFields = (event: any) => {
        const { target: { name, value } } = event;
        this.setState({ [name]: value });
        if (name == 'emailId') {
            this.validateEmailId(value);
        }
        else if (name == 'password') {
            if (this.state.validEmail && this.state.token !== null && this.state.repassword === value) {
                this.setState({ disableRegisterButton: false });
            }
            else {
                this.setState({ disableRegisterButton: true });
            }
        }
        else if (name == 'repassword') {
            if (this.state.validEmail && this.state.token !== null && this.state.password === value) {
                this.setState({ disableRegisterButton: false });
            }
            else {
                this.setState({ disableRegisterButton: true });
            }
        }
        else {
            this.toggleRegisterButton();
        }
    }

    validateEmailId = (emailAddress: string) => {
        var emailValid = false;
        if (!!emailAddress.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) emailValid = true;
        this.setState({ validEmail: emailValid });

        if (emailValid && this.state.token !== null && this.passwordMismatchCheck() === 1) {
            this.setState({ disableRegisterButton: false });
        }
        else {
            this.setState({ disableRegisterButton: true });
        }
    }

    toggleRegisterButton = () => {
        if (this.state.validEmail && this.state.token !== null && this.passwordMismatchCheck() === 1) {
            this.setState({ disableRegisterButton: false });
        }
        else {
            this.setState({ disableRegisterButton: true });
        }
    }

    submitFormFields = () => {
        debugger;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                emailId: this.state.emailId,
                password: this.state.password,
                token: this.state.token
            })
        };
        this.setState({ disableAllFields: true });
        fetch('api/CleverPiData/RegisterUser', requestOptions)
            .then(response => response.json())
            .then(response => {
                debugger;
                this.setState({ disableAllFields: false });
                console.log(response)
                if (response.ErrorMessage && response.ErrorMessage !== "") {
                    debugger;
                    console.log("Response message : " + response.ErrorMessage);

                }
                else {
                    debugger;
                    this.props.showEmailSentModal(response.EmailId);
                }

            });
    }


    hasNumber = () => {
        var value = this.state.password;
        return new RegExp(/[0-9]/).test(value);
    }
    hasMixed = () => {
        var value = this.state.password;
        return new RegExp(/[a-z]/).test(value) &&
            new RegExp(/[A-Z]/).test(value);
    }
    hasSpecial = () => {
        var value = this.state.password;
        return new RegExp(/[!#@$%^&*)(+=._-]/).test(value);
    }

    strengthIndicator = () => {
        var value: string = this.state.password;

        let strengths = 0;
        if (value.length > 5)
            strengths++;
        if (value.length > 7)
            strengths++;
        if (this.hasNumber())
            strengths++;
        if (this.hasSpecial())
            strengths++;
        if (this.hasMixed())
            strengths++;
        return strengths;
    }

    strengthColor = (count: number) => {
        if (count < 2)
            return 'red';
        if (count < 3)
            return 'darkorange';
        if (count < 4)
            return 'lightgreen';
        if (count < 5)
            return 'green';
        if (count < 6)
            return 'darkgreen';
    }

    strengthText = (count: number) => {
        if (this.state.password.trim() == '')
            return '';
        if (count < 2)
            return 'Very Weak Password';
        if (count < 3)
            return 'Weak Password';
        if (count < 4)
            return 'Medium Password';
        if (count < 5)
            return 'Strong Password';
        if (count < 6)
            return 'Very Strong Password';
    }

    passwordMismatchCheck = () => {
        if (this.state.repassword.trim() === "")
            return 0;
        if (this.state.password === this.state.repassword)
            return 1;
        else
            return 2;
    }

    render = () => {
        debugger;
        const strength = this.strengthIndicator();
        const bgColor = this.strengthColor(strength);
        const strengthText = this.strengthText(strength);
        const passwordMismatch = this.passwordMismatchCheck();

        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                style={{ opacity: 1 }}>

                <Modal.Header>

                    <Modal.Title id="contained-modal-title-vcenter" >
                        Register

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <p>
                        Please fill the details below.
                    </p>
                    Email Id &nbsp;
                    <input type="email"
                        name="emailId"
                        className={this.state.validEmail ? "form-control validTextStyle" : "form-control invalidTextStyle"}
                        placeholder="email@example.com"
                        value={this.state.emailId}
                        onChange={this.setFormFields}
                        disabled={this.state.disableAllFields} />
                    <br />
                    Password &nbsp;
                    <input type="password"
                        name="password"
                        className="form-control"
                        placeholder="enter your passsword"
                        onChange={this.setFormFields}
                        style={{ borderColor: bgColor }}
                        disabled={this.state.disableAllFields} />
                    <span style={{ float: 'right', color: bgColor }}>{strengthText}</span>
                    <br />
                    Re-Enter Password &nbsp;
                    <input type="password"
                        name="repassword"
                        className="form-control"
                        placeholder="re-enter your passsword"
                        onChange={this.setFormFields}
                        disabled={this.state.disableAllFields} />
                    {passwordMismatch === 1 ?
                        <span style={{ float: 'right', color: 'green' }}>Password matched</span>
                        :
                        passwordMismatch !== 0 ?
                            <span style={{ float: 'right', color: 'red' }}>Password don't match</span>
                            : null
                    }
                    <br />
                    <span>By checking on the I'm not a robot check box below, you are also agreeing to the&nbsp;
                        <a href="#">Clever Pi Terms &amp; Conditions</a>
                    </span>
                    <br /><br />
                    <ReCAPTCHA
                        style={{ display: "inline-block" }}
                        ref={this._reCaptchaRef}
                        sitekey={appSettings.SiteKey}
                        onChange={this.handleChange}
                        asyncScriptOnLoad={this.asyncScriptOnLoad}
                    /*disabled={this.state.disableAllFields}*/
                    />
                </Modal.Body>
                <Modal.Footer>

                    <div style={this.state.disableAllFields ? { display: 'block' } : { display: 'none' }}>
                        <FontAwesomeIcon icon={faSpinner} className={"fa-spin"} /> Processing your request...
                    </div>

                    <Button onClick={this.submitFormFields} disabled={this.state.disableRegisterButton || this.state.disableAllFields} >Register</Button>
                    <Button onClick={this.props.onHide} disabled={this.state.disableAllFields}>Cancel</Button>

                </Modal.Footer>

            </Modal>);
    }
}